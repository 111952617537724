@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,800;1,300;1,600;1,700&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

.eng * {
  box-sizing: border-box;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
}
.kor *,
.jpn * {
  box-sizing: border-box;
  font-family: 'Noto Sans JP', Helvetica, Arial, sans-serif !important;
}

html,
body,
#root {
  min-height: calc(100vh - 90px); /*header included*/
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only print {
  #widget-Botsonic {
    display: none;
  }
}
